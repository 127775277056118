<template>
	<div class="exp-container height-fill">
		<div class="index-row">
			<div class="index-left-pane-container" v-if="flags.showExperiments">
				<v-sheet
					class="left-pane d-flex flex-column height-fill"
					elevation="2"
					color="white"
					rounded
				>
					<div class="d-flex flex-row pane-input-container bottom-border-thin">
						<v-icon>mdi-magnify</v-icon>
						<input
							type="text"
							class="pane-input"
							placeholder="Search experiments"
							v-model="experimentData.search"
						/>
					</div>
					<v-list
						v-if="experimentData.result"
						dense
						class="experiment-list-container"
					>
						<v-list-item class="experiment-entry" link>
							<v-list-item-content>
								<v-list-item-title>Select All</v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-checkbox
									color="primary"
									hide-details
									v-model="flags.bulkExperimentToggle.state"
									:disabled="flags.bulkExperimentToggle.disabled"
									@change="bulkExperimentToggle()"
								></v-checkbox>
							</v-list-item-action>
						</v-list-item>
						<v-list-item
							v-for="(item, index) in experimentData.result"
							:key="item.uri.id"
							class="experiment-entry"
							link
						>
							<v-list-item-content>
								<v-list-item-title v-text="item.name"></v-list-item-title>
							</v-list-item-content>
							<v-list-item-action>
								<v-radio-group :value="item.state" dense>
									<v-radio
										color="primary"
										hide-details
										:value="true"
										@click="experimentToggleEvent(item)"
									></v-radio>
									<v-radio
										class="hidden-radio-btn"
										hide-details
										:value="false"
									></v-radio>
								</v-radio-group>
							</v-list-item-action>
						</v-list-item>
					</v-list>
				</v-sheet>
			</div>
			<div class="index-draggable-pane"></div>
			<div class="index-right-pane-container">
				<v-card>
					<div class="view-menu">
						<v-tooltip bottom transition="fade-transition">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-bind="attrs"
									v-on="on"
									@click="saveSchemaAsTemplate()"
								>
									<v-icon>mdi-layers-plus</v-icon>
								</v-btn>
							</template>
							<span>Add Changes to Template</span>
						</v-tooltip>
						<v-tooltip bottom transition="fade-transition">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-bind="attrs"
									v-on="on"
									@click="toggleTemplateView()"
								>
									<v-icon v-if="flags.templatized_view"
										>mdi-layers-minus</v-icon
									>
									<v-icon v-else>mdi-layers</v-icon>
								</v-btn>
							</template>
							<span v-if="flags.templatized_view">Remove Template</span>
							<span v-else>Apply Template</span>
						</v-tooltip>
						<v-tooltip bottom transition="fade-transition">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									icon
									v-bind="attrs"
									v-on="on"
									@click="flags.templateDeletion.confirmation = true"
								>
									<v-icon>mdi-layers-remove</v-icon>
								</v-btn>
							</template>
							<span>Delete Template</span>
						</v-tooltip>
						<v-tooltip bottom transition="fade-transition">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									icon
									:color="flags.showFilters ? 'primary' : ''"
									@click="flags.showFilters = !flags.showFilters"
								>
									<v-icon>{{
										flags.showFilters
											? "mdi-filter-plus-outline"
											: "mdi-filter-outline"
									}}</v-icon>
								</v-btn>
							</template>
							<span>Apply Filters</span>
						</v-tooltip>
						<v-btn
							icon
							v-if="selectedTrials.length >= 1 && selectedTrials.length <= 100"
							color="red"
							@click="deleteTrials()"
						>
							<v-icon v-tooltip="'Delete Selected Trials'"
								>mdi-delete-outline</v-icon
							>
						</v-btn>
					</div>
					<v-card-text>
						<div class="d-flex flex-row align-center">
							<div v-html="pageTitle" class="experiment-header"></div>
							<v-btn
								v-if="getExperiment && experimentData.selection.length == 1"
								icon
								@click="experimentEditId = getExperiment.uri.id"
								><v-icon v-tooltip="'Hide Experiment List'"
									>mdi-pencil-outline</v-icon
								></v-btn
							>
						</div>
						<div v-if="getExperiment && experimentData.selection.length == 1">
							<div>Description: {{ getExperiment.desc }}</div>
							<div class="d-flex flex-row align-center">
								<div>Experiment Identifier: {{ getExperiment.uri.id }}</div>
								<v-btn icon small @click="copyExperimentIdentifier()"
									><v-icon small>mdi-content-copy</v-icon></v-btn
								>
							</div>
							<!-- <div>Story Points: {{ getExperiment.story_points }}</div> -->
							<div v-if="getExperiment.confluence_link">
								<a
									v-bind:href="getExperiment.confluence_link"
									target="_blank"
									class="primary-link"
									>Confluence Link
									<v-icon small>mdi-text-box-outline</v-icon></a
								>
							</div>
							<div v-if="getExperiment.jira_link">
								<a
									v-bind:href="getExperiment.jira_link"
									class="primary-link"
									target="_blank"
									>Jira Story/Task <v-icon small>mdi-calendar-check</v-icon></a
								>
							</div>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-btn
							small
							color="primary"
							outlined
							@click="fetchTrials('refresh')"
							:loading="flags.loading.trials.refresh"
							>Refresh <v-icon right dark> mdi-refresh-circle </v-icon></v-btn
						>
						<v-btn
							small
							color="primary"
							outlined
							:loading="flags.exportLoading"
							@click="exportTrials()"
							>Export Trials<v-icon right dark> mdi-download </v-icon>
						</v-btn>
						<!-- <v-btn small color="primary">Filter By Status</v-btn> -->
						<v-badge
							overlap
							icon="mdi-check"
							:value="trialData.statusFilter != ''"
							><v-menu>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										outlined
										color="primary"
										dark
										v-bind="attrs"
										v-on="on"
										small
										class="ml-2"
									>
										Filter By Status
										<v-icon right dark> mdi-filter-variant </v-icon>
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item
										link
										v-for="item in trialStatusItems"
										:key="item.value"
										@click="trialData.statusFilter = item.value"
									>
										<v-list-item-subtitle
											>{{ item.text }}
											<v-icon
												right
												color="success"
												v-if="item.value == trialData.statusFilter"
											>
												mdi-check
											</v-icon>
										</v-list-item-subtitle>
									</v-list-item>
								</v-list>
							</v-menu></v-badge
						>
						<v-btn
							small
							color="primary"
							outlined
							class="ml-2"
							@click="flags.selectColumns = true"
							>Change Columns <v-icon right dark> mdi-view-column </v-icon>
						</v-btn>
						<v-btn
							small
							color="primary"
							outlined
							v-if="selectedTrials.length > 1 && selectedTrials.length <= 3"
							@click="compareTrials()"
							>Compare ({{ selectedTrials.length }})
							<v-icon right dark> mdi-select-compare </v-icon></v-btn
						>
					</v-card-actions>
					<div v-if="flags.showFilters" class="advanced-filters">
						<!-- <v-select
							dense
							label="Group By"
							outlined
							v-model="trialGrouping.groupBy"
							:items="trialGrouping.groupingOptions"
							@change="fetchTrials('global')"
						>
						</v-select> -->
						<v-switch
							inset
							label="Only Show My Trials"
							class="personalization-switch"
							@change="fetchTrials('global')"
							v-model="flags.showUserSpecificTrials"
						>
						</v-switch>
					</div>
				</v-card>
				<v-card class="mt-2" :loading="flags.loading.trials.global">
					<v-card-title>
						<v-text-field
							v-on:keyup.enter="fetchTrials('query')"
							v-model="trialData.query"
							:append-icon="queryIcon"
							single-line
							label="Search by query"
							class="query-field"
							placeholder="for e.g. metrics.acc > 0.85 and meta.tranche = &#39;sagemaker-run-1&#39;"
							:loading="flags.loading.trials.query"
						></v-text-field>
					</v-card-title>
					<v-data-table
						:height="tableHeight"
						v-if="trialData.parsed_output"
						:headers="trackerSchema.selectedHeaders"
						:items="trialData.parsed_output.results"
						:server-items-length="trialData.parsed_output.count"
						class="trial-table mb-2"
						:fixed-header="true"
						v-model="selectedTrials"
						show-select
						:single-select="false"
						item-key="uri.id"
						:options.sync="trialOptions"
						:footer-props="{
							'items-per-page-options': [50, 75, 100, 150, 200],
						}"
						dense
						:group-by="trialGrouping.groupbyForDataTable"
					>
						<!-- group-by="tranche"
          show-group-by -->

						<template v-slot:[`item.name`]="{ item }">
							<td class="sticky-column-name">
								<router-link
									tag="a"
									target="_blank"
									:to="{ name: 'trial', params: { id: item.uri.id } }"
									class="primary-link"
								>
									{{ item.name }}
								</router-link>
							</td>
						</template>

						<template v-slot:[`header.name`]="{ header }">
							{{ header.text }}
						</template>

						<template v-slot:[`item.experiment_name`]="{ item }">
							<v-badge
								dot
								inline
								v-if="item.status == 'in_progress'"
								color="deep-purple"
							>
								{{ item.experiment_name }}
							</v-badge>
							<span v-else>{{ item.experiment_name }}</span>
						</template>
						<template v-slot:[`item.status`]="{ item }">
							{{ item.status | status }}
						</template>
						<template v-slot:[`item.created_on`]="{ item }">
							{{ item.created_on | localdatetime }}
						</template>
					</v-data-table>
				</v-card>
			</div>
		</div>
		<EditExperiment
			@closed="modalClosed('editExperiment')"
			v-bind:id="experimentData.experimentEditId"
		/>
		<ColumnSelection
			v-bind:flagVisibility="flags.selectColumns"
			@saved="modalClosed('columnsChanged')"
			@closed="modalClosed('columnsSelClosed')"
		/>
		<TrialDeletionView
			:trial-ids="trialsTobeDeleted"
			@closed="modalClosed('deletionCancelled')"
			@deleted="modalClosed('trialsDeleted')"
		/>
		<v-dialog
			persistent
			max-width="500px"
			v-model="flags.templateDeletion.confirmation"
		>
			<v-card>
				<v-card-title>
					<span class="headline">Confirm Deletion</span>
				</v-card-title>
				<v-card-text>
					<p>
						Are you sure you want to delete your template for this project ?
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="error"
						small
						@click="deleteTemplate()"
						:loading="flags.templateDeletion.isDeleting"
						>Delete
					</v-btn>
					<v-btn text small @click="flags.templateDeletion.confirmation = false"
						>Cancel</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog persistent max-width="500px" v-model="flags.overrideMessage">
			<v-card>
				<v-card-title>Alert</v-card-title>
				<v-card-text>
					<p>
						We noticed that you are trying to use sorting action from both order
						by clause in query as well column based sorting from the Data Table,
						but we cannot allow that. OneML will prioritize query based order by
						over Data Table Sorting. If you wish to sort only from the Data
						Table, please remove the order by clause from the query first.!
					</p>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn text small @click="flags.overrideMessage = false">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import copy from "copy-to-clipboard";
import { mapGetters } from "vuex";
import EditExperiment from "../../components/experiments/ExperimentEdit.vue";
import ColumnSelection from "../../components/experiments/ColumnSelection.vue";
import TrialDeletionView from "../../components/trials/TrialDeletionView.vue";
import { specialKeys } from "@/store/tracking/config";
// import ResizeHandler
import ResizeHandler from "@/store/common/resizeHandler.js";
import ProjectOrTenantMismatchError from "@/store/errors/ProjectOrTenantMismatchError";

export default {
	name: "ExperimentList",
	components: {
		EditExperiment,
		ColumnSelection,
		TrialDeletionView,
		ResizeHandler,
	},
	computed: {
		...mapGetters([
			"getSelectedProject",
			"getExperiments",
			"getTrials",
			"getExperiment",
			"getExperimentColumns",
		]),
		pageTitle() {
			let title = "NA";
			try {
				if (this.getTrials == null) title = "Fetching trials";
				else if (this.experimentData.selection.length == 1) {
					const selectedId = this.experimentData.selection[0];
					const name = this.getExperiments.results.filter(
						(e) => e.uri.id == selectedId
					)[0].name;
					title = `Trials from <b>${name}</b>`;
				} else if (
					this.experimentData.selection.length ==
					this.getExperiments.results.length
				)
					title = `Showing trials from all the experiments`;
				else if (this.experimentData.selection.length > 1)
					title = `Showing trials from ${this.experimentData.selection.length} experiments`;
				else title = "Select an experiment to show trials";
				// add information about template
				if (this.flags.templatized_view) {
					title += " [Template View]";
				}
			} catch {
				title = "NA";
			}
			return title;
		},
		queryIcon() {
			if (this.flags.loading.trials.query) return "mdi-clock-fast";
			else if (this.flags.queryStatus == "error") return "mdi-alert-circle";
			else if (this.flags.queryStatus == "success") return "mdi-check-circle";
			return "mdi-magnify";
		},
		tableHeight() {
			// Calculate height dynamically based on viewport height minus 318px
			return `${window.innerHeight - 318}px`;
		},
	},
	data() {
		return {
			flags: {
				bulkExperimentToggle: {
					state: true,
					disabled: true,
				},
				templatized_view: true,
				selectColumns: false,
				showExperiments: true,
				showFilters: false,
				showUserSpecificTrials: false,
				queryStatus: "idle",
				loading: {
					trials: {
						refresh: false,
						query: false,
						global: false,
					},
					experiments: false,
				},
				templateDeletion: {
					confirmation: false,
					isDeleting: false,
				},
				overrideMessage: false,
			},
			trialStatusItems: [
				{ text: "Any", value: "" },
				{ text: "Completed", value: "completed" },
				{ text: "Open", value: "open" },
				{ text: "In Progress", value: "in_progress" },
				{ text: "Crashed", value: "crashed" },
			],
			experimentOptions: {
				id: null,
				page: 1,
				itemsPerPage: 100,
				search: null,
			},
			trialOptions: {
				init: true,
				page: 1,
				itemsPerPage: 50,
			},
			trackerSchema: {
				staticHeaders: [
					{ text: "name", value: "name", class: "sticky-column-name" },
					{ text: "experiment", value: "experiment_name", sortable: false },
					{ text: "tranche", value: "tranche" },
					{ text: "created on", value: "created_on" },
					{ text: "status", value: "status" },
				],
				dynamicHeaders: [],
				selectedHeaders: [],
			},
			displayPrecision: 5,
			experimentData: {
				search: null,
				selection: [],
				result: [],
				experimentEditId: -1,
				preselectedExperiment: null,
			},
			trialData: {
				query: null,
				statusFilter: "",
				parsed_output: null,
			},
			selectedTrials: [],
			trialsTobeDeleted: [],
			trialGrouping: {
				groupBy: null,
				groupingOptions: [
					{ text: "None", value: null },
					{ text: "Tranche", value: "tranche" },
				],
				groupbyForDataTable: null,
			},
			resizableEventData: {
				experimentPane: null,
				trialPane: null,
				dragAnchor: null,
				resizeHandler: null,
			},
		};
	},
	watch: {
		"experimentData.search": function (newVal) {
			if (newVal != this.experimentOptions.search) {
				this.experimentOptions.search = newVal;
				this.fetchExperiments();
			}
		},
		trialOptions: function (newOptions) {
			if (!this.trialOptions.init) {
				// console.log('trial options changed');
				this.fetchTrials("global");
			}
			this.trialOptions.init = false;
		},
		"trialData.statusFilter": function (newStatus) {
			this.fetchTrials("global");
		},
	},
	async created() {
		await this.init();
	},
	mounted() {
		this.resizableEventData.experimentPane = document.querySelector(
			".index-left-pane-container"
		);
		this.resizableEventData.dragAnchor = document.querySelector(
			".index-draggable-pane"
		);
		this.resizableEventData.trialPane = document.querySelector(
			".index-right-pane-container"
		);
		//add event listeners for mousedown, up & move
		this.resizableEventData.resizeHandler = new ResizeHandler(
			this.resizableEventData.experimentPane,
			this.resizableEventData.trialPane
		);

		this.resizableEventData.dragAnchor.addEventListener(
			"mousedown",
			this.resizableEventData.resizeHandler.onMouseDown
		);
		document.addEventListener(
			"mouseup",
			this.resizableEventData.resizeHandler.onMouseUp
		);
		document.addEventListener(
			"mousemove",
			this.resizableEventData.resizeHandler.onMouseMove
		);
	},
	beforeDestroy() {
		//clean up event listeners
		this.resizableEventData.dragAnchor.removeEventListener(
			"mousedown",
			this.resizableEventData.resizeHandler.onMouseDown
		);
		document.removeEventListener(
			"mouseup",
			this.resizableEventData.resizeHandler.onMouseUp
		);
		document.removeEventListener(
			"mousemove",
			this.resizableEventData.resizeHandler.onMouseMove
		);
	},
	methods: {
		async init() {
			await this.$store.dispatch("initTemplateStore");
			if (this.$route.query.id) {
				this.flags.templatized_view = false;
			}
			if (this.flags.templatized_view) await this.applyTemplate();
			this.experimentOptions.id = this.getSelectedProject.uri.id;
			const exp_name = this.$route.query.name;
			if (exp_name != undefined && exp_name.length > 1) {
				this.exp_search = exp_name;
			} else {
				await this.fetchExperiments();
			}
		},
		async fetchExperiments() {
			// console.log("loading experiments");
			this.flags.loading.experiments = true;
			this.experimentData.selection = [];
			const experimentDetailId = this.$route.query.id;
			await this.$store.dispatch(
				"fetchExperimentsByProject",
				this.experimentOptions
			);
			this.experimentData.result = [];
			this.getExperiments.results.forEach((element) => {
				this.experimentData.result.push(element);
				if (
					experimentDetailId == element.uri.id ||
					(!this.flags.templatized_view && experimentDetailId == undefined) ||
					(this.flags.templatized_view &&
						this.experimentData.preselectedExperiment != undefined &&
						element.uri.id in this.experimentData.preselectedExperiment)
				) {
					element.state = true;
					if (this.experimentData.selection.indexOf(element.uri.id) == -1)
						this.experimentData.selection.push(element.uri.id);
				} else {
					element.state = false;
				}
			});
			if (experimentDetailId != undefined)
				this.fetchExperiment(experimentDetailId);
			this.fetchTrials("global");
			this.flags.loading.experiments = false;
		},
		parseObject(obj, prefix) {
			let headers = [];
			if (obj == null) return headers;
			Object.entries(obj).forEach(([key, value], i) => {
				if (specialKeys.indexOf(key) != -1) return;
				if (typeof value == "object") {
					const newPrefix = `${prefix}.${key}`;
					const subHeaders = this.parseObject(value, newPrefix);
					subHeaders.forEach((k) => {
						if (headers.indexOf(k) == -1 && k.length > 0) {
							headers.push(k);
						}
					});
				} else if (headers.indexOf(key) == -1 && key.length > 0) {
					headers.push(`${prefix}.${key}`);
				}
				if (
					typeof obj[key] == "number" &&
					!Number.isInteger(obj[key]) &&
					obj[key].toString().split(".").splice(1)[0].length >
						this.displayPrecision
				) {
					obj[key] = value.toFixed(this.displayPrecision);
				}
			});
			return headers;
		},
		compareSchemas(schema1, schema2) {
			const schema1Set = new Set(schema1.map((e) => e.value));
			const schema2Set = new Set(schema2.map((e) => e.value));
			return (
				schema1Set.size === schema2Set.size &&
				[...schema1Set].every((value) => schema2Set.has(value))
			);
		},
		updateSelectedColumnsInStore() {
			const selectedColumns = new Set(
				this.trackerSchema.selectedHeaders.map((e) => e.value)
			);
			this.trackerSchema.dynamicHeaders.forEach((e) => {
				e.selected = selectedColumns.has(e.value);
			});
			this.$store.commit(
				"SET_EXPERIMENT_COLUMNS",
				this.trackerSchema.dynamicHeaders
			);
		},
		parseTrialsForSchema() {
			let newHeaders = {};
			this.trialData.parsed_output.results.forEach((element) => {
				let subHeaders = this.parseObject(element.params, "params");
				subHeaders.forEach((e) => {
					if (newHeaders[e] == undefined)
						newHeaders[e] = {
							text: e.substr(7),
							value: e,
							cellClass: "param-cell",
							selected: true,
							colType: 0,
						};
				});
				subHeaders = this.parseObject(element.metrics, "metrics");
				subHeaders.forEach((e) => {
					if (newHeaders[e] == undefined)
						newHeaders[e] = {
							text: e.substr(8),
							value: e,
							cellClass: "metric-cell",
							selected: true,
							colType: 1,
						};
				});
			});
			const newHeadersArray = Object.values(newHeaders);
			newHeadersArray.sort((a, b) => a.colType - b.colType);
			let flagUpdateSelectedSchema = false;
			/*Logic
      update seleted schema if
      1) selectedHeaders is empty (meaning this is the first time schema is being set)
      2) templated view is not enabled
      3) previous dynamic headers (copy of newHeaders from last attempt) are not the same as the newHeaders
      */
			if (
				!this.flags.templatized_view &&
				(this.trackerSchema.selectedHeaders.length == 0 ||
					!this.compareSchemas(
						this.trackerSchema.dynamicHeaders,
						newHeadersArray
					))
			) {
				console.log("updating selected columns");
				this.trackerSchema.selectedHeaders =
					this.trackerSchema.staticHeaders.concat(newHeadersArray);
			}
			this.trackerSchema.dynamicHeaders = newHeadersArray;
			this.updateSelectedColumnsInStore();
			// set group by on data table after the ordered items are set to avoid UI jitters
			this.trialGrouping.groupbyForDataTable = this.trialGrouping.groupBy;
			// console.log(this.trialGrouping.groupbyForDataTable);
		},
		prepareTrialOptions() {
			let optionsCopy = structuredClone(this.trialOptions);
			// console.log(optionsCopy.sortBy);
			optionsCopy.project_id = this.getSelectedProject.uri.id;
			optionsCopy.query =
				this.trialData.query == null ? "" : this.trialData.query;
			optionsCopy.user_specific_trials = this.flags.showUserSpecificTrials;
			optionsCopy.exp_idx = this.experimentData.selection;
			optionsCopy.filter_status =
				this.trialData.statusFilter == null ? "" : this.trialData.statusFilter;
			if (this.trialGrouping.groupBy != null) {
				optionsCopy.order_by = "experiment_name";
			} else if (optionsCopy.sortBy != null && optionsCopy.sortBy.length > 0) {
				let sortByColumn = optionsCopy.sortBy[0];
				let metaMapping = {
					name: "meta.name",
					tranche: "meta.tranche",
					status: "meta.status",
					created_on: "meta.created_on",
				};

				// Check if the sortByColumn exists in the mapping
				if (Object.prototype.hasOwnProperty.call(metaMapping, sortByColumn)) {
					sortByColumn = metaMapping[sortByColumn];
				}
				sortByColumn = sortByColumn.replace("_uri.", "__");
				if (optionsCopy.sortDesc[0]) {
					optionsCopy.order_by = sortByColumn + " desc";
				} else {
					optionsCopy.order_by = sortByColumn + " asc";
				}
			} else {
				optionsCopy.order_by = "";
			}

			return optionsCopy;
		},
		async fetchTrials(loadingAttribute) {
			this.flags.loading.trials[loadingAttribute] = true;
			if (loadingAttribute == "query") {
				this.flags.queryStatus = "idle";
			}
			// console.log("loading trials");
			this.loading = true;
			const optionsCopy = this.prepareTrialOptions();
			const orderByRegex = /\bO\s*R\s*D\s*E\s*R\s+B\s*Y\b/i;
			if (optionsCopy.order_by != "" && orderByRegex.test(optionsCopy.query)) {
				this.flags.overrideMessage = true;
			}
			try {
				await this.$store.dispatch("fetchTrials", optionsCopy);
				this.trialData.parsed_output = structuredClone(this.getTrials);
				this.parseTrialsForSchema();
			} catch (error) {
				console.log(error);
				if (loadingAttribute == "query") {
					this.flags.queryStatus = "error";
				} else {
					this.$store.commit("showNotification", "Oops! Something went wrong.");
				}
			} finally {
				this.flags.loading.trials[loadingAttribute] = false;
				if (loadingAttribute == "query" && this.flags.queryStatus != "error") {
					this.flags.queryStatus = "success";
				} else if (loadingAttribute != "query") this.flags.queryStatus = "idle";
			}
		},
		exportTrials() {
			this.exportLoading = true;
			const optionsCopy = this.prepareTrialOptions();
			this.$store
				.dispatch("exportTrials", optionsCopy)
				.then((data) => {
					const anchor = document.createElement("a");
					anchor.href =
						"data:application/json-lines;charset=utf-8," +
						encodeURIComponent(data);
					anchor.target = "_blank";
					anchor.download = "OneML Trials.jsonl";
					anchor.click();
				})
				.finally(() => (this.exportLoading = false));
		},
		bulkExperimentToggle() {
			// select all the experiments
			if (this.flags.bulkExperimentToggle.state) {
				this.experimentData.result.forEach((e) => {
					e.state = true;
				});
			}
			this.renderExperimentSelection();
		},
		experimentToggleEvent(item) {
			/*
      There are three scenarios:
        if the number of selected experiment is equal to number of experiments, then current item state should be set to true & all other items state should be set to false
        if the number of selected experiment is less than number of experiments, then current item state should be toggled
      */
			if (
				this.experimentData.selection.length ==
				this.experimentData.result.length
			) {
				this.experimentData.result.forEach((e) => {
					e.state = false;
				});
				item.state = true;
			} else {
				item.state = !item.state;
			}
			this.renderExperimentSelection();
		},
		renderExperimentSelection() {
			let newSelection = [];
			this.experimentData.result.forEach((e) => {
				if (e.state) newSelection.push(e.uri.id);
			});
			if (newSelection.length == 0) {
				this.trialData.parsed_output = null;
				this.fetchExperiment(null);
				this.experimentData.selection = [];
			} else {
				this.experimentData.selection = newSelection;
				this.fetchTrials("global");
				if (this.experimentData.selection.length == 1)
					this.fetchExperiment(this.experimentData.selection[0]);
				// if (
				// 	this.experimentData.selection.length > 1 &&
				// 	this.trackerSchema.dynamicHeaders.length > 0
				// ) {
				// 	if (this.trackerSchema.dynamicHeaders[0].value != "experiment.name")
				// 		this.trackerSchema.dynamicHeaders.splice(0, 0, {
				// 			text: "Experiment",
				// 			value: "experiment.name",
				// 		});
				// } else if (this.experimentData.selection.length <= 1) {
				// 	if (this.trackerSchema.dynamicHeaders[0].value == "experiment.name")
				// 		this.trackerSchema.dynamicHeaders.splice(0, 1);

				// }
			}
			// un-select select-all checkbox if number of selected experiments are less than total experiments
			if (
				this.experimentData.selection.length < this.experimentData.result.length
			) {
				this.flags.bulkExperimentToggle.state = false;
				this.flags.bulkExperimentToggle.disabled = false;
			} else if (
				this.experimentData.selection.length ==
				this.experimentData.result.length
			) {
				this.flags.bulkExperimentToggle.state = true;
				this.flags.bulkExperimentToggle.disabled = true;
			}
		},
		viewChanged() {
			this.$store.commit(
				"showNotification",
				"Chart based-UI has been disabled"
			);
		},
		async fetchExperiment(id) {
			this.loading = true;
			try {
				await this.$store.dispatch("fetchExperiment", id);
			} catch (error) {
				if (error instanceof ProjectOrTenantMismatchError) {
					// Navigate to the project's landing page if project IDs do not match
					const experimentProjectId = error.projectId;
					this.$router.push({
						path: `/projects/${experimentProjectId}/landing`,
						query: { callback_url: this.$route.fullPath },
					});
				} else {
					console.error("An error occurred:", error);
				}
			} finally {
				this.loading = false;
			}
		},
		copyExperimentIdentifier() {
			copy(this.getExperiments.identifier);
			this.$store.commit(
				"showNotification",
				"Experiment identifier copied to clipboard"
			);
		},
		modalClosed(type) {
			if (type == "editExperiment") {
				this.fetchExperiment(this.experimentData.experimentEditId);
				this.experimentData.experimentEditId = -1;
			} else if (type == "columnsChanged") {
				this.flags.selectColumns = false;
				let newColumns = [];
				this.getExperimentColumns.forEach((e) => {
					if (e.selected) newColumns.push(e);
				});
				this.trackerSchema.selectedHeaders =
					this.trackerSchema.staticHeaders.concat(newColumns);
			} else if (type == "columnsSelClosed") {
				this.flags.selectColumns = false;
			} else if (type == "deletionCancelled") {
				this.trialsTobeDeleted = [];
			} else if (type == "trialsDeleted") {
				this.trialsTobeDeleted = [];
				this.selectedTrials = [];
				this.fetchTrials("global");
			}
		},
		compareTrials() {
			//select uri.id field from selectedTrials using javascript map function & join as csv
			const trialUris = this.selectedTrials
				.map((trial) => trial.uri.id)
				.join(",");
			//encode the csv string
			const encodedUris = encodeURIComponent(trialUris);
			// //open in new tab
			const routeData = this.$router.resolve({
				name: "compare-trials",
				query: { uris: encodedUris },
			});
			window.open(routeData.href, "_blank");
		},
		deleteTrials() {
			this.trialsTobeDeleted = this.selectedTrials.map((trial) => trial.uri.id);
		},
		saveSchemaAsTemplate() {
			// create a deep copy of experiment data except the result field
			const experimentLookup = {};
			const experimentDataCopy = structuredClone(this.experimentData);
			experimentDataCopy.result = null;
			// convert experimentDataCopy.selection array to a dictionary
			experimentDataCopy.selection.forEach((e) => {
				experimentLookup[e] = null;
			});
			experimentDataCopy.preselectedExperiment = experimentLookup;
			// create a deep copy of trial data except the parsed_output field
			const trialDataCopy = structuredClone(this.trialData);
			trialDataCopy.parsed_output = null;
			// create a deep copy of tracker schema
			const trackerSchemaCopy = structuredClone(this.trackerSchema);
			const template = {
				experimentData: experimentDataCopy,
				trialData: trialDataCopy,
				trackerSchema: trackerSchemaCopy,
				createdOn: new Date().toISOString(),
			};
			this.$store.dispatch("addTemplate", {
				id: this.getSelectedProject.uri.id,
				data: template,
			});
			this.flags.templatized_view = true;
			this.$store.commit("showNotification", "Template saved successfully");
		},
		async applyTemplate() {
			// try to get the template for this project from the store
			const template = await this.$store.dispatch(
				"getTemplate",
				this.getSelectedProject.uri.id
			);
			if (template != null) {
				this.flags.templatized_view = true;
				this.experimentData = template.experimentData;
				this.trialData = template.trialData;
				this.trackerSchema = template.trackerSchema;
			} else {
				this.flags.templatized_view = false;
			}
		},
		async toggleTemplateView() {
			const template = await this.$store.dispatch(
				"getTemplate",
				this.getSelectedProject.uri.id
			);
			if (template == null) {
				this.$store.commit(
					"showNotification",
					"You haven't yet created a template for this project"
				);
				return;
			}
			this.flags.templatized_view = !this.flags.templatized_view;
			// explcitly remove query
			if (!this.flags.templatized_view) {
				this.trialData.query = null;
			}
			//we need to remove the id from route if we want to apply template.
			if (this.$route.query.id != undefined || this.$route.query.id != null) {
				this.$router.replace({ query: null });
			}
			await this.init();
			if (this.flags.templatized_view) {
				this.$store.commit("showNotification", "Template applied to your view");
			} else {
				this.$store.commit(
					"showNotification",
					"Template removed from your view"
				);
			}
		},
		async deleteTemplate() {
			this.flags.templateDeletion.isDeleting = true;
			await this.$store.dispatch(
				"deleteTemplate",
				this.getSelectedProject.uri.id
			);
			this.$store.commit("showNotification", "Template deleted successfully");
			this.flags.templateDeletion.confirmation = false;
			this.flags.templatized_view = false;
			await this.init();
		},
		async vhToPixels(vh) {
			return Math.round(window.innerHeight * (vh / 100));
		},
	},
};
</script>

<style scoped>
.personalization-switch {
	margin-top: 6px !important;
	margin-bottom: -10px !important;
	/* background-color: red !important; */
}
.sticky-column-name {
	position: sticky;
	left: 0;
	background-color: white;
	z-index: 3;
}
.sticky-column-name-header {
	position: sticky;
	left: 0;
	z-index: 4;
}
::v-deep .trial-table table {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important ;
	padding: 0;
}
</style>

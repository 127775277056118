<template>
	<div class="artifact-container">
		<v-row
			style="height: inherit"
			v-if="getArtifacts && getArtifacts.count > 0"
		>
			<v-col cols="3" class="">
				<v-sheet class="" elevation="2" color="white" rounded>
					<v-list dense>
						<div v-for="item in getNonServableArtifacts" :key="item.uri.id">
							<v-list-item
								v-if="!item.hasChildren"
								:class="{ 'grey-background': item.uri.id === artifactId }"
								link
								@click="selectArtifact(item.uri.id)"
								:disabled="!item.ready_to_serve"
							>
								<v-list-item-content>
									<v-list-item-title
										>{{ item.object_name }}
										<v-icon
											v-if="!item.ready_to_serve"
											class="ml-2"
											color="success"
										>
											mdi-cloud-sync-outline
										</v-icon>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
							<v-list-group v-else link>
								<template v-slot:activator>
									<v-list-item-content>
										<v-list-item-title>
											{{ item.object_name | charlimit20 }}
											<v-chip small class="ml-2" outlined label color="success"
												>{{ item.nChildren + 1 }} versions</v-chip
											>
										</v-list-item-title>
									</v-list-item-content>
								</template>
								<v-list-item link @click="fetchArtifactFn(item.uri.id)">
									<v-list-item-content>
										<v-list-item-subtitle
											>{{ item.object_name }}
											<v-chip small class="ml-2" outlined label
												>v{{ item.version }}</v-chip
											>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
								<v-list-item
									link
									v-for="child in item.children"
									:key="child.uri.id"
									@click="fetchArtifactFn(child.uri.id)"
								>
									<v-list-item-content>
										<v-list-item-subtitle
											>{{ child.object_name }}
											<v-chip small class="ml-2" outlined label
												>v{{ child.version }}</v-chip
											>
										</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list-group>
						</div>
						<v-list-group
							v-if="servable"
							link
							@click="selectArtifact(servable.uri.id)"
						>
							<template v-slot:activator>
								<v-list-item-title>{{
									servable.object_name
								}}</v-list-item-title>
							</template>
							<v-list-group
								v-for="entry in servableEntries"
								:key="entry.name"
								v-if="entry.isDir"
								no-action
								sub-group
							>
								<template v-slot:activator>
									<v-list-item-content>
										<v-list-item-title>{{ entry.name }}</v-list-item-title>
									</v-list-item-content>
								</template>
								<v-list-item
									v-for="subDir in Object.entries(entry.value)"
									:key="subDir[0]"
									link
								>
									<v-list-item-content>
										<v-list-item-subtitle>{{ subDir[0] }}</v-list-item-subtitle>
									</v-list-item-content>
								</v-list-item>
							</v-list-group>
							<v-list-item
								v-for="entry in servableEntries"
								:key="entry.name"
								v-if="!entry.isDir"
							>
								<v-list-item-content>
									<v-list-item-subtitle>{{ entry.name }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list-group>
					</v-list>
				</v-sheet>
			</v-col>
			<v-col cols="9">
				<v-card v-if="getArtifact" :loading="loading">
					<v-toolbar flat>
						<div class="mt-2">
							<v-toolbar-title>{{ getArtifact.object_name }} </v-toolbar-title>
							<div class="d-flex flex-row align-center text-caption">
								<div>Artifact URI: {{ getArtifact.uri.id }}</div>
								<v-btn icon small @click="copyArtifactIdentifier()">
									<v-icon>mdi-content-copy</v-icon>
								</v-btn>
							</div>
						</div>
						<v-spacer></v-spacer>
						<div>
							<v-chip
								class=""
								color="success"
								outlined
								v-if="getArtifact.chosen_for_servable"
							>
								<v-icon left> mdi-check-circle </v-icon>
								Servable
							</v-chip>
							<v-chip class="ml-2" color="primary" outlined>
								<v-icon left> mdi-source-branch </v-icon>
								v{{ getArtifact.version }}
							</v-chip>
							<v-chip class="ml-2" color="primary" outlined>
								<v-icon left> mdi-harddisk </v-icon>
								{{ getArtifact.size | fileSize }}
							</v-chip>
							<v-chip class="ml-2" color="primary" dark outlined>
								<v-icon left> mdi-clock-time-five-outline </v-icon>
								{{ getArtifact.created_on | localdatetime }}
							</v-chip>
						</div>
					</v-toolbar>
					<v-banner single-line>
						<template v-slot:actions>
							<div class="text-center">
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn v-bind="attrs" v-on="on" small text color="primary">
											Servable Options
											<v-icon right dark> mdi-package-variant </v-icon>
										</v-btn>
									</template>
									<v-list>
										<v-list-item
											link
											small
											@click="artifactForServableId = getArtifact.uri.id"
											v-if="
												isUserLevel3OrUp &&
												artifact_type == 'artifact_servable' &&
												!getArtifact.chosen_for_servable
											"
										>
											<v-list-item-subtitle
												>Create Servable</v-list-item-subtitle
											>
											<v-list-item-icon>
												<v-icon>mdi-package-variant-closed-plus</v-icon>
											</v-list-item-icon>
										</v-list-item>
										<v-list-item
											link
											small
											:to="{
												name: 'publish-artifact',
												params: { id: getArtifact.uri.id },
												query: { trial: $route.params.id },
											}"
											v-if="
												isUserLevel2OrUp && artifact_type == 'artifact_servable'
											"
										>
											<v-list-item-subtitle
												>Publish to Model Store</v-list-item-subtitle
											>
											<v-list-item-icon>
												<v-icon>mdi-store-plus-outline</v-icon>
											</v-list-item-icon>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
							<v-btn color="primary" small text @click="downloadArtifact()">
								Download
								<v-icon right dark> mdi-cloud-download-outline </v-icon>
							</v-btn>
							<v-btn
								color="red"
								small
								text
								@click="confirmDeletion = true"
								:disabled="getArtifact.chosen_for_servable"
							>
								Delete <v-icon right dark> mdi-delete </v-icon>
							</v-btn>
						</template>
					</v-banner>
					<v-card-text class="grey lighten-4">
						<v-sheet
							max-width="900"
							class="artifact-sheet"
							v-if="contentPreviewAvailable"
						>
							<v-sheet
								elevation="1"
								v-if="artifact_type == 'artifact_text'"
								class="text-content"
							>
								{{ artifact_content }}
							</v-sheet>
							<SingleLabelReport
								v-else-if="artifact_type == 'artifact_sklearn_clf_report_json'"
								v-bind:report="artifact_content"
							/>
							<div
								v-else-if="
									artifact_type == 'artifact_sklearn_sl_conf_matrix_json'
								"
								class=""
							>
								<SingleLabelReport v-bind:report="artifact_content" />
							</div>
							<div
								v-else-if="
									artifact_type == 'artifact_sklearn_ml_conf_matrix_json'
								"
								class=""
							>
								<MultiLabelReport v-bind:reportArray="artifact_content" />
							</div>
							<v-sheet
								elevation="1"
								v-else-if="artifact_type == 'artifact_json'"
								class="json-content"
							>
								<pre v-html="JSON.stringify(artifact_content, null, 2)"></pre>
							</v-sheet>
							<img
								alt="logged artifact image"
								v-bind:src="artifact_content"
								class="responsive"
								v-else-if="artifact_type == 'artifact_image'"
							/>
						</v-sheet>
						<div v-else class="mx-auto no-content">
							Content preview is not available for this type of artifact
						</div>
					</v-card-text>
				</v-card>
				<div class="text-caption" v-else>Select an artifact from left pane</div>
			</v-col>
		</v-row>
		<div v-else>
			<div class="text-center">
				<div>No artifacts found for this trial</div>
				<!-- <img
          src="@/assets/doggo.jpg"
          alt="logo"
          class="generic-page-image"
          height="100"
        /> -->
				<div class="text-caption">Such Empty! Much wow!</div>
			</div>
		</div>
		<div class="text-center">
			<v-dialog
				width="500"
				v-model="confirmDeletion"
				v-if="getArtifact"
				persistent
			>
				<v-card>
					<v-card-title class="text-body-2 grey lighten-2">
						Alert
					</v-card-title>
					<v-card-text>
						Are you sure you want to delete this artifact with name
						{{ getArtifact.object_name }} ? <br />Once deleted, an artifact
						cannot be recovered.
					</v-card-text>
					<v-divider></v-divider>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="error" small @click="deleteArtifact()">
							Delete
						</v-btn>
						<v-btn color="" @click="confirmDeletion = false" small>
							Cancel
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<NewServable
			v-bind:id="artifactForServableId"
			@closed="modalClosed('newServable')"
		/>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import copy from "copy-to-clipboard";
import SingleLabelReport from "../../components/artifacts/SingleLabelReport.vue";
import MultiLabelReport from "../../components/artifacts/MultiLabelReport.vue";
import NewServable from "../servables/NewServable.vue";
import ProjectOrTenantMismatchError from "@/store/errors/ProjectOrTenantMismatchError";

export default {
	name: "ArtifactView",
	components: { SingleLabelReport, MultiLabelReport, NewServable },
	props: {
		artifactId: {
			type: String,
			default: null,
		},
	},
	computed: {
		...mapGetters([
			"getArtifacts",
			"getArtifact",
			"isUserLevel3OrUp",
			"isUserLevel2OrUp",
		]),
		getNonServableArtifacts() {
			if (!this.getArtifacts.results) {
				return [];
			}
			var artifactMap = {};
			// load all the artifacts whole version is 1 & artifact type is not servable into the map
			this.getArtifacts.results.forEach((item) => {
				item.hasChildren = false;
				item.nChildren = 0;
				if (item.artifact_type != "artifact_servable" && item.version == 1) {
					artifactMap[item.uri.id] = item;
				}
			});
			// load all the artifacts whole version is not 1 & artifact type is not servable into the map
			this.getArtifacts.results.forEach((item) => {
				if (item.artifact_type != "artifact_servable" && item.version != 1) {
					if (item.parent_artifact_id in artifactMap) {
						let parent = artifactMap[item.parent_artifact_id];
						parent.hasChildren = true;
						if (!parent.children) {
							parent.children = [];
						}
						item.object_name = parent.object_name;
						parent.children.push(item);
						parent.nChildren += 1;
					} else {
						item.hasChildren = false;
						artifactMap[item.uri.id] = item;
					}
				}
			});
			return Object.values(artifactMap);
		},
	},
	data() {
		return {
			artifactMap: null,
			supportedContentTypes: [
				"artifact_text",
				"artifact_json",
				"artifact_image",
				"artifact_sklearn_clf_report_json",
				"artifact_sklearn_sl_conf_matrix_json",
				"artifact_sklearn_ml_conf_matrix_json",
			],
			contentPreviewAvailable: false,
			artifact_type: null,
			artifact_content: null,
			loading: false,
			servable: null,
			servableEntries: null,
			confirmDeletion: false,
			loadingDelete: false,
			artifactForServableId: null,
		};
	},
	created() {
		this.init();
		if (this.artifactId) {
			this.fetchArtifactFn(this.artifactId);
		}
	},
	methods: {
		init() {
			this.$store
				.dispatch("fetchArtifacts", {
					trial_id: this.$route.params.id,
				})
				.then(() => {
					this.artifactMap = {};
					let servableId = null;
					//check if servable is available & fetch it
					this.getArtifacts.results.forEach((element) => {
						this.artifactMap[element.uri.id] = element;
						if (element.artifact_type == "artifact_servable") {
							servableId = element.uri.id;
						}
					});
					if (servableId != null) {
						this.$store
							.dispatch("fetchArtifact", {
								id: servableId,
								download: false,
								commit: false,
							})
							.then((data) => {
								this.servable = data;
								if (
									this.servable.object_name == "Servable.tar.gz" ||
									this.servable.object_name == "Model.tar.gz"
								) {
									this.servable.object_name = "Model";
								}
								this.servableEntries = [];
								Object.entries(this.servable.artifact_metadata).forEach((e) =>
									this.servableEntries.push({
										name: e[0],
										value: e[1],
										isDir: typeof e[1] == "object",
									})
								);
							});
					}
					if (this.artifactId) {
						this.fetchArtifactFn(this.artifactId);
					}
				});
			this.$store.commit("SET_ARTIFACT", null);
		},
		async fetchArtifactFn(id) {
			this.contentPreviewAvailable = false;
			const artifact = this.artifactMap[id];
			this.artifact_type = artifact.artifact_type;
			const fetchContent =
				this.supportedContentTypes.indexOf(artifact.artifact_type) >= 0;
			this.loading = true;
			try {
				await this.$store.dispatch("fetchArtifact", {
					id: id,
					download: true,
					commit: true,
				});
				if (fetchContent) {
					if (this.artifact_type == "artifact_image")
						this.artifact_content = this.getArtifact.presigned_download_url;
					else {
						axios.get(this.getArtifact.presigned_download_url).then((resp) => {
							this.artifact_content = resp.data;
							// console.log(this.artifact_content);
						});
					}
				}
			} catch (error) {
				if (error instanceof ProjectOrTenantMismatchError) {
					// Navigate to the project's landing page if project IDs do not match
					const ProjectId = error.projectId;
					this.$router.push({
						path: `/projects/${ProjectId}/landing`,
						query: { callback_url: this.$route.fullPath },
					});
				} else {
					console.error("An error occurred:", error);
				}
			} finally {
				setTimeout(() => {
					// this delay is needed to avoid content flickering
					this.loading = false;
					this.contentPreviewAvailable = fetchContent;
				}, 1000);
			}
		},
		copyArtifactIdentifier() {
			copy(this.getArtifact.uri.id);
			this.$store.commit(
				"showNotification",
				"Artifact identifier copied to clipboard"
			);
		},
		downloadArtifact() {
			if (
				this.getArtifact &&
				this.getArtifact.presigned_download_url != undefined
			) {
				var link = document.createElement("a");
				link.setAttribute("download", this.getArtifact.object_name);
				link.href = this.getArtifact.presigned_download_url;
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		},
		deleteArtifact() {
			this.loadingDelete = true;
			this.$store
				.dispatch("deleteArtifact", this.getArtifact.uri.id)
				.then(() => {
					this.init();
					this.$store.commit(
						"showNotification",
						"Artifact has been successfully deleted"
					);
					this.$store.commit("SET_ARTIFACT", null);
				})
				.finally(() => {
					this.loadingDelete = false;
					this.confirmDeletion = false;
				});
		},
		// parseArtifactName(name) {
		//   let newName = name;
		//   switch (name) {
		//     case "sklearn_clf_report.json":
		//       newName = "Classification Report";
		//       break;
		//     case "sklearn_sl_conf_matrix.json":
		//       newName = "Confusion Matrix";
		//       break;
		//     case "sklearn_ml_conf_matrix.json":
		//       newName = "Multi-label Confusion Matrix";
		//       break;
		//   }
		//   return newName;
		// },
		selectArtifact(id) {
			this.artifactId = id;
			this.$router.push({ query: { tab: "artifacts", artifact_id: id } });
			this.fetchArtifactFn(id);
		},
		modalClosed(type) {
			switch (type) {
				case "newServable":
					this.fetchArtifactFn(this.artifactForServableId);
					this.artifactForServableId = null;
					break;
			}
		},
	},
};
</script>

<style>
.grey-background {
	background-color: rgba(0, 0, 0, 0.12) !important;
}
</style>

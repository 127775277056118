<template>
	<v-row class="card-row mb-3" v-if="getTrial">
		<v-col cols="9">
			<v-card>
				<div class="trial-floation-actions">
					<v-chip class="" color="primary" outlined>
						<v-icon left> mdi-account-circle-outline </v-icon>
						{{ getTrial.created_by.username }}
					</v-chip>
					<v-chip class="ml-2" color="primary" dark outlined>
						<v-icon left> mdi-alarm </v-icon>
						{{ timeTaken }}
					</v-chip>
				</div>
				<div class="breadcrumb">
					<router-link
						class="primary-link"
						:to="{
							name: 'trials',
							query: { id: getTrial.experiment.id },
						}"
						>{{ getTrial.experiment.name }}</router-link
					>
					>
					{{ getTrial.name }}
				</div>
				<v-card-title>{{ getTrial.name }}</v-card-title>
				<v-row>
					<v-col>
						<v-row class="info-container">
							<v-col cols="4"
								><div class="info-card">
									Tranche: {{ getTrial.tranche }}
								</div></v-col
							>
							<v-col cols="4"
								><div class="info-card">
									Status:
									<v-chip small :color="statusColor" class="status-chip" dark>{{
										getTrial.status | status
									}}</v-chip>
								</div>
							</v-col>
							<v-col cols="4"
								><div class="info-card">
									Created On: {{ getTrial.created_on | localdatetime }}
								</div></v-col
							>
							<v-col cols="4"
								><div class="info-card">
									Finished On: {{ getTrial.finished_on | localdatetime }}
								</div></v-col
							>
							<v-col cols="4"
								><div class="info-card">
									Git Commit: {{ getTrial.git_commit_id }}
								</div></v-col
							>
							<v-col cols="4"
								><div class="info-card">
									DVC Commit: {{ getTrial.dvc_commit_id }}
								</div></v-col
							>
							<v-col cols="12"
								><div class="info-card">
									Dataset Identifier: {{ getTrial.dataset_identifier }}
								</div></v-col
							>
						</v-row>
					</v-col>
					<v-col v-if="perCompletion != null" cols="2">
						<div class="text-center">
							<v-progress-circular
								:size="100"
								:width="10"
								:value="perCompletion"
								:color="getTrial.status == 'crashed' ? 'error' : 'primary'"
								background-color="grey lighten-3"
							>
								{{ perCompletion }}%
							</v-progress-circular>
						</div>
					</v-col>
				</v-row>
				<v-card-actions>
					<v-btn
						v-if="getTrial.status == 'in_progress' || getTrial.status == 'open'"
						text
						small
						color="success"
						@click="refresh()"
					>
						Refresh <v-icon right>mdi-cached</v-icon>
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-card class="mt-6">
				<v-expansion-panels flat>
					<v-expansion-panel>
						<v-expansion-panel-header> Notes </v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="d-flex flex-column">
								<v-textarea
									v-if="flags.noteEdit"
									counter
									v-model="note"
									class="notes-textarea"
								></v-textarea>
								<div v-else class="notes-div">{{ note }}</div>
								<div>
									<v-btn icon @click="saveNotes()" v-if="flags.noteEdit"
										><v-icon>mdi-content-save</v-icon></v-btn
									>
									<v-btn icon v-else @click="flags.noteEdit = true"
										><v-icon>mdi-pencil</v-icon></v-btn
									>
								</div>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
			<v-card class="mt-3">
				<v-expansion-panels flat v-model="flags.params" multiple>
					<v-expansion-panel>
						<v-expansion-panel-header>
							Hyper-Parameters
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="params-table" v-if="params">
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Name</th>
											<th class="text-left">Value</th>
										</tr>
									</thead>
									<tbody>
										<tr
											v-for="(item, index) in loggedTrials"
											:key="`${item.key}_${index}`"
										>
											<td>{{ item.key }}</td>
											<td>
												<v-tooltip right transition="fade-transition">
													<template v-slot:activator="{ on, attrs }">
														<v-chip
															small
															label
															close-icon="mdi-link"
															close
															@click:close="openLoggedTrial(item.value)"
															v-bind="attrs"
															v-on="on"
														>
															{{ item.value.name }}
														</v-chip>
													</template>
													<span v-if="item.value.experiment != null">
														From {{ item.value.experiment.name }}
													</span>
												</v-tooltip>
											</td>
										</tr>
										<tr v-for="item in Object.entries(params)" :key="item[0]">
											<td>{{ item[0] }}</td>
											<td>{{ item[1] }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
			<v-card class="mt-3">
				<v-expansion-panels flat v-model="flags.metrics" multiple>
					<v-expansion-panel>
						<v-expansion-panel-header> Metrics </v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-simple-table dense class="params-table" v-if="metrics">
								<template v-slot:default>
									<thead>
										<tr>
											<th class="text-left">Name</th>
											<th class="text-left">Value</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="item in Object.entries(metrics)" :key="item[0]">
											<td>{{ item[0] }}</td>
											<td>{{ item[1] }}</td>
										</tr>
									</tbody>
								</template>
							</v-simple-table>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-card>
		</v-col>
		<v-col>
			<v-card>
				<v-card-text>
					<h4>Environment Details</h4>
					<v-list dense>
						<v-list-item
							link
							v-for="entry in Object.entries(getTrial.env_details)"
							:key="entry[0]"
						>
							<v-list-item-content>
								<v-list-item-title class="primary-link"
									>{{ entry[0] | charlimit20 }} v{{
										entry[1]
									}}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<h4>Host Details</h4>
					<v-list dense>
						<v-list-item
							link
							v-for="entry in Object.entries(getTrial.host_details)"
							:key="entry[0]"
						>
							<v-list-item-content>
								<v-list-item-title class="primary-link" v-tooltip="entry[1]"
									>{{ entry[0] }} :
									{{ entry[1] | charlimit20 }}</v-list-item-title
								>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { formatTimeSpent } from "@/store/common/utils";
import { specialKeys } from "@/store/tracking/config";
import { is } from "vee-validate/dist/rules";
import ProjectOrTenantMismatchError from "@/store/errors/ProjectOrTenantMismatchError";

export default {
	name: "TrialOverview",
	components: { formatTimeSpent },
	computed: {
		...mapGetters(["getTrial"]),
		statusColor() {
			let color = "success";
			switch (this.getTrial.status) {
				case "open":
					color = "blue";
					break;
				case "crashed":
					color = "red";
					break;
				case "in_progress":
					color = "deep-purple";
					break;
				default:
					color = "success";
					break;
			}
			return color;
		},
	},
	data() {
		return {
			displayPrecision: 5,
			loading: false,
			timeTaken: null,
			flags: {
				noteEdit: false,
				params: [0],
				metrics: [0],
			},
			note: null,
			metrics: null,
			params: null,
			perCompletion: null,
			timer: null,
			loggedTrials: [],
		};
	},
	async created() {
		await this.fetchTrialById(this.$route.params.id);
		// schedule self after 2 seconds if status is in_progress
		// if (this.getTrial.status == "in_progress") {
		//   this.timer = setInterval(() => {
		//     // disable global loading
		//     this.$store.commit("updateGlobalLoading", false);
		//     this.fetchTrialById(this.$route.params.id);
		//   }, 3500);
		// }
	},
	methods: {
		captureLoggedTrials(obj, prefix) {
			Object.entries(obj).forEach(([key, value], i) => {
				this.loggedTrials.push({
					key: prefix != null ? `${prefix}.${key}` : key,
					value: value,
				});
			});
		},
		reduceObject(obj, prefix, isHidden = false) {
			let items = {};
			if (obj == null) return items;
			Object.entries(obj).forEach(([key, value], i) => {
				isHidden = key == "_hidden";
				const newPrefix =
					prefix != null ? `${prefix}.${key}` : isHidden ? null : key;
				if (key == "_trial") {
					this.captureLoggedTrials(value, prefix);
				} else if (typeof value == "object") {
					const subItems = this.reduceObject(value, newPrefix, isHidden);
					Object.entries(subItems).forEach(([k, v], i) => {
						if (!(k in items) && k.length > 0) {
							const itemKey = isHidden ? k + "*" : k;
							items[itemKey] = v;
						}
					});
				} else if (!(key in items) && key.length > 0) {
					if (value == undefined || value == null) {
						value = "null";
					} else if (
						typeof value == "number" &&
						!Number.isInteger(obj[key]) &&
						value.toString().split(".").splice(1)[0].length >
							this.displayPrecision
					) {
						value = value.toFixed(this.displayPrecision);
					}
					items[newPrefix] = value;
				}
			});
			return items;
		},
		// make fetchTrialById async
		async fetchTrialById(id) {
			this.loggedTrials = [];
			try {
				await this.$store.dispatch("fetchTrial", id);
				this.note = this.getTrial.notes;
				const d1 = new Date(this.getTrial.created_on);
				const d2 =
					this.getTrial.finished_on == null
						? Date.now()
						: new Date(this.getTrial.finished_on);
				this.timeTaken = formatTimeSpent(d2 - d1);
				this.metrics = this.reduceObject(this.getTrial.metrics, null);
				this.params = this.reduceObject(this.getTrial.params, null);
				let totalSteps = 0;
				for (const [key, value] of Object.entries(this.params)) {
					if (key.toLowerCase().includes("epochs")) {
						totalSteps = value;
						break;
					}
				}
				if (totalSteps > 0 && this.getTrial.status != "completed") {
					this.perCompletion = parseInt(
						(this.getTrial.trial_step_count / totalSteps) * 100
					);
				}
			} catch (error) {
				if (error instanceof ProjectOrTenantMismatchError) {
					const experimentProjectId = error.projectId;
					this.$router.push({
						path: `/projects/${experimentProjectId}/landing`,
						query: { callback_url: this.$route.fullPath },
					});
				} else {
					console.error("An error occurred:", error);
				}
			}
		},
		saveNotes() {
			this.$store
				.dispatch("patchTrial", {
					id: this.$route.params.id,
					data: { notes: this.note },
				})
				.then((data) => {
					this.note = data.notes;
					this.flags.noteEdit = false;
					this.$store.commit(
						"showNotification",
						"Your note was successfully saved!"
					);
				});
		},
		openLoggedTrial(trial) {
			//open trial in new tab
			const routeData = this.$router.resolve({
				name: "trial",
				params: { id: trial.id },
			});
			window.open(routeData.href, "_blank");
		},
		refresh() {
			this.$store.commit("updateGlobalLoading", false);
			this.fetchTrialById(this.$route.params.id);
		},
	},
	beforeDestroy() {
		if (this.timer) {
			console.log("clearing timer");
			this.$store.commit("updateGlobalLoading", true);
			clearInterval(this.timer);
		}
	},
};
</script>

<template>
	<v-row>
		<v-col class="card-container">
			<v-card class="mx-auto" width="450" :loading="loading">
				<!-- Close Button -->
				<v-btn
					icon
					small
					@click="cancelProjectSwitch"
					class="controls-top-right"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
				<!-- Card Title -->
				<v-card-title class="text-h6 font-weight-bold">
					Project Selection Alert
				</v-card-title>
				<!-- Card Message -->
				<v-card-text v-if="!loading">
					<div class="mb-4" style="line-height: 1.6">
						It looks like the project you are trying to access is different from
						your current selection. Would you like to automatically select this
						project & the corresponding tenant?
					</div>
					<div>
						Selection :
						<v-chip small
							><strong>{{ getSelectedTenant.name }}</strong
							>/<strong>{{ getSelectedProject.name }}</strong></v-chip
						>
					</div>
					<div class="mt-2">
						Destination :
						<v-chip small
							><strong>{{ getTenant.name }}</strong
							>/<strong>{{ getProject.name }}</strong></v-chip
						>
					</div>
				</v-card-text>
				<v-card-text v-else>
					Hang on while we check the project link ...
				</v-card-text>
				<v-divider></v-divider>
				<v-list-item lines="two" link @click="switchProjectAndTenant()">
					<v-list-item-title>Continue</v-list-item-title>
					<v-list-item-icon right>
						<v-icon>mdi-chevron-right</v-icon>
					</v-list-item-icon>
				</v-list-item>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "ProjectLanding",
	computed: {
		...mapGetters([
			"getSelectedProject",
			"getSelectedTenant",
			"getProject",
			"getTenant",
		]),
	},
	data() {
		return {
			loading: true,
		};
	},
	async created() {
		if (
			this.getSelectedProject != null &&
			this.getSelectedProject.uri.id == this.$route.params.projectId
		) {
			return this.$router.push({ name: "home" });
		} else {
			await this.fetchProjectAndTenantDetails();
		}
	},
	methods: {
		async fetchProjectAndTenantDetails() {
			this.loading = true;
			try {
				await this.$store.dispatch(
					"fetchProject",
					this.$route.params.projectId
				);
				await this.$store.dispatch("fetchTenant", this.getProject.tenant_id);
			} catch {
				this.$store.commit("showNotification", "Oops! Something went wrong.");
			} finally {
				this.loading = false;
			}
		},
		async switchProjectAndTenant() {
			await this.$store.commit("SET_SELECTED_TENANT", this.getTenant);
			await this.$store.commit("SET_SELECTED_PROJECT", this.getProject);
			if (this.$route?.query?.callback_url) {
				console.log(this.$route?.query?.callback_url);

				this.$router.push({ path: this.$route?.query?.callback_url });
			} else {
				this.$router.push({ name: "home" });
			}
		},
		cancelProjectSwitch() {
			this.$router.push({ name: "home" });
		},
	},
};
</script>

<style scoped></style>

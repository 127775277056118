import { TrackingClient } from "@/store/common/axio-api";
import * as utils from "@/store/common/utils";
import ProjectOrTenantMismatchError from "../errors/ProjectOrTenantMismatchError";

const state = {
  artifacts: null,
  artifact: null,
};

const getters = {
  getArtifacts: (state) => state.artifacts,
  getArtifact: (state) => state.artifact,
};

const mutations = {
  SET_ARTIFACTS: (state, artifacts) => (state.artifacts = artifacts),
  SET_ARTIFACT: (state, artifact) => (state.artifact = artifact),
  CLEAR_DATA(state) {
    state.artifact = null;
  },
};

const actions = {
  fetchArtifacts({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      const baseUrl = utils.prepareURL(
        `trials/${options.trial_id}/artifacts?`,
        options
      );
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          commit("SET_ARTIFACTS", response.data);
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  fetchArtifact({ commit, rootGetters }, options) {
    return new Promise((resolve, reject) => {
      let baseUrl = `artifacts/${options.id}`;
      if (options.download) baseUrl += "/download";
      TrackingClient.get(baseUrl, rootGetters.getAuthHeaders)
        .then(function (response) {
          const selectedProjectId = rootGetters.getSelectedProject?.uri?.id;
          const artifactData = response.data;
          if (artifactData.project_id === selectedProjectId) {
            if (options.commit != undefined && options.commit != false) commit("SET_ARTIFACT", response.data);
            resolve(artifactData);
          } else {
            // Throw custom error if project IDs do not match
            const error = new ProjectOrTenantMismatchError("Selected project ID does not match artifact's project ID.", artifactData.project_id);
            reject(error);
          }

        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  deleteArtifact({ rootGetters }, id) {
    return new Promise((resolve, reject) => {
      TrackingClient.delete(`/artifacts/${id}`, rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  publishToStore({ rootGetters }, options) {
    return new Promise((resolve, reject) => {
      TrackingClient.post(`/artifacts/${options.id}/publish/`,
        options.modelCard,
        rootGetters.getAuthHeaders)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  },
  clearArtifact({ commit }) {
    commit("CLEAR_DATA");
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
